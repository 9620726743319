<template>
  <div>
    <p>Answer each of the questions listed below.</p>

    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">a) Which of the following elements are considered to be metals?</p>
      <v-row justify="space-around" class="mb-5">
        <div v-for="option in options" :key="option.value">
          <v-checkbox
            v-model="inputs.input1"
            :value="option.value"
            :dense="true"
            hide-details="true"
            :ripple="false"
            :label="option.text"
          >
            <template #label>
              <stemble-latex :content="`$\\ce{${option.text}}$`" />
            </template>
          </v-checkbox>
        </div>
      </v-row>

      <p class="mb-3">
        b) Which of the following elements are considered to be semi-metals (metalloids)?
      </p>
      <v-row justify="space-around" class="mb-5">
        <div v-for="option in options" :key="option.value">
          <v-checkbox
            v-model="inputs.input2"
            :value="option.value"
            :dense="true"
            hide-details="true"
            :ripple="false"
            :label="option.text"
          >
            <template #label>
              <stemble-latex :content="`$\\ce{${option.text}}$`" />
            </template>
          </v-checkbox>
        </div>
      </v-row>

      <p class="mb-3">c) Which of the following are considered to be nonmetals?</p>
      <v-row justify="space-around" class="mb-2">
        <div v-for="option in options" :key="option.value">
          <v-checkbox
            v-model="inputs.input3"
            :value="option.value"
            :dense="true"
            hide-details="true"
            :ripple="false"
            :label="option.text"
          >
            <template #label>
              <stemble-latex :content="`$\\ce{${option.text}}$`" />
            </template>
          </v-checkbox>
        </div>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question11',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: [],
        input3: [],
      },
      options: [
        {text: 'Se', value: 'selenium'},
        {text: 'Kr', value: 'krypton'},
        {text: 'Si', value: 'silicon'},
        {text: 'Sn', value: 'tin'},
        {text: 'Ga', value: 'gallium'},
        {text: 'As', value: 'arsenic'},
        {text: 'Bi', value: 'bismuth'},
      ],
    };
  },
};
</script>
